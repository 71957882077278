import { SiteHeader } from './components';
import { Home, Clinical, Research, SelectPapers, News, Book, Error } from './containers';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Pallete } from './style';

const measurmentID = process.env.REACT_APP_ANALYTICS_MEASUREMENT_ID;

const titlePathMap = new Map<string, string>([
  ['/', 'Home'],
  ['/clinical-bio', 'Clinical Biography'],
  ['/research-bio', 'Research Biography'],
  ['/select-papers', 'Select Papers'],
  ['/latest-news', 'News'],
  ['/shamans-among-us', 'Book']
]);

function App() {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 768);
  const location = useLocation();

  // Google Analytics
  useEffect(() => {
    ReactGA.initialize(measurmentID as string);
  }, []);

  // Report page view
  useEffect(() => {
    let pageTitle = titlePathMap.get(window.location.pathname) ?? 'Page Not Found';
    ReactGA.ga('send', 'pageview', pageTitle);
    document.title = `Dr. Joseph Polimeni | ${pageTitle}`;
  }, [location]);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 768);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  return (
    <div>
      <SiteHeader />
      <div
        className="background-image"
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        }}
      />
      <div className="content-container">
        <div
          className={isDesktop ? 'main-container' : 'main-container-mobile'}
          style={isDesktop ? { display: 'flex', justifyContent: 'center' } : {}}
        >
          <div style={isDesktop ? { width: '80vw', maxWidth: 1090 } : {}}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/clinical-bio" element={<Clinical />} />
              <Route path="/research-bio" element={<Research />} />
              <Route path="/select-papers" element={<SelectPapers />} />
              <Route path="/latest-news" element={<News />} />
              <Route path="/shamans-among-us" element={<Book />} />
              <Route path="/*" element={<Error />} />
            </Routes>
          </div>
        </div>
        <div
          style={{
            color: Pallete.Silver,
            opacity: 0.5,
            margin: '10px',
            width: '100%',
            textAlign: 'center',
            fontSize: '10pt'
          }}
        >
          © 2024 Adrian Polimeni & Joseph Polimeni. All rights reserved.
        </div>
      </div>
    </div>
  );
}

export default App;
