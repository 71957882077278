import { Card, Typography, List, Flex } from 'antd';
import { PaperListItem, VoxArticleItem, YoutubeNewsItem } from '../components';
import NewsListItem from '../components/NewsListItem';
import '../App.css';

const { Title } = Typography;

const newsContent = [
  {
    date: new Date(2022, 11, 19),
    content: <VoxArticleItem date={new Date(2022, 11, 19)} />
  },
  {
    date: new Date(2023, 9, 18),
    content: (
      <YoutubeNewsItem
        title="Evolving Psychiatry Podcast #29"
        embedID="0_tOAYcdpuA"
        description="Dr. Polimeni joins host Adam Hunt on The Evolving Psychiatry podcast to discuss shamanism and his hypothesis regarding the evolution of schizophrenia."
        date={new Date(2023, 9, 18)}
      />
    )
  },
  {
    date: new Date(2013, 8, 15),
    content: (
      <YoutubeNewsItem
        title="Shamanism and the Evolutionary Origins of Schizophrenia"
        embedID="tJN3D8UhtGQ"
        description="Video lecture on shamanism and the evolutionary origins of schizophrenia. Based on the book Shamans Among Us."
        date={new Date(2013, 8, 15)}
        useCustomThumbnail="./images/custom-thumbnail.png"
      />
    )
  },
  {
    date: new Date(2012, 10, 24),
    content: (
      <YoutubeNewsItem
        title="Shamans Among Us Trailer"
        embedID="dCwJV01XHwQ"
        description="Trailer for the book Shamans Among Us by Dr. Joseph Polimeni."
        date={new Date(2012, 10, 24)}
      />
    )
  },
  {
    title: 'Shamans Among Us now on EvoEbooks',
    date: new Date(2012, 10, 1),
    content: (
      <Flex>
        <div style={{ padding: '0px 0px 40px 0px' }}>
          I'm pleased to announce that the Kindle version of Shamans Among Us will be distributed by EvoEbooks, a new
          publishing house dedicated to publishing ebooks on applied evolution.
        </div>
        <img alt="evobooks logo" src="./images/book/evobookslogo.jpg" height={80} />
      </Flex>
    )
  },
  {
    date: new Date(2014, 1, 1),
    content: (
      <PaperListItem
        title={'The Canadian Journal of Psychiatry Book Review: Shamans Among Us'}
        authors={['Mary V Seeman, MD']}
        year={'2014'}
        journal="The Canadian Journal of Psychiatry, Volume: 59, Issue: 2"
        hrefPDF={'./pdf/shamans-book-review.pdf'}
      />
    )
  }
];

const News = () => {
  return (
    <Card
      title={
        <Title level={2} style={{ margin: '10px 0px 0px 0px ' }}>
          Latest News
        </Title>
      }
    >
      <List
        itemLayout="horizontal"
        dataSource={newsContent.sort(function (a, b) {
          return b.date.getTime() - a.date.getTime();
        })}
        renderItem={(item) => (
          <List.Item>
            {item.title ? (
              <NewsListItem title={item.title} date={item.date}>
                {item.content}
              </NewsListItem>
            ) : (
              item.content
            )}
          </List.Item>
        )}
      />
    </Card>
  );
};
export default News;
