import { Button, ConfigProvider, Row } from 'antd';
import { Pallete } from '../style';

export interface BookButtonProps {
  text: string;
  href: string;
  icon: React.ReactNode;
  mobileMode?: boolean;
}

const BookButton = (prop: BookButtonProps) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            defaultActiveBorderColor: Pallete.Silver,
            defaultActiveColor: Pallete.Black,
            defaultActiveBg: Pallete.Silver,
            defaultHoverBg: Pallete.White,
            defaultHoverColor: Pallete.Black,
            defaultHoverBorderColor: Pallete.Silver
          }
        }
      }}
    >
      {prop.mobileMode ? (
        <Button icon={prop.icon} href={prop.href} target="_blank" block>
          {prop.text}
        </Button>
      ) : (
        <Button href={prop.href} target="_blank" style={{ width: 100, minHeight: 100, minWidth: 100 }}>
          <Row style={{ fontSize: 50, marginTop: 4, marginBottom: 4, justifyContent: 'center' }}>{prop.icon}</Row>
          <Row style={{ justifyContent: 'center' }}>{prop.text}</Row>
        </Button>
      )}
    </ConfigProvider>
  );
};
export default BookButton;
