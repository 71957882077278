import { Card, List, Typography, Divider } from 'antd';
import { PaperListItem } from '../components';
import '../App.css';

const { Title } = Typography;

const papersData = [
  {
    title: 'The First Joke: Exploring the Evolutionary Origins of Humor',
    authors: ['Joseph Polimeni', 'Jeffrey P. Reiss'],
    year: '2006',
    journal: 'Evolutionary Psychology, Volume: 4, Issue: 1',
    hrefPDF: './pdf/the-first-joke.pdf',
    hrefGoogleScholar:
      'https://scholar.google.ca/citations?view_op=view_citation&hl=en&user=2K0Hs3kAAAAJ&citation_for_view=2K0Hs3kAAAAJ:ufrVoPGSRksC'
  },
  {
    title: 'How shamanism and group selection may reveal the origins of schizophrenia',
    authors: ['Joseph Polimeni', 'Jeffrey P. Reiss'],
    year: '2002',
    journal: 'Medical Hypotheses, Volume: 58, Issue: 3',
    hrefPDF: './pdf/how-shamanism-and-group-selection-may-reveal-the-origins-of-schizophrenia.pdf',
    hrefGoogleScholar:
      'https://scholar.google.ca/citations?view_op=view_citation&hl=en&user=2K0Hs3kAAAAJ&citation_for_view=2K0Hs3kAAAAJ:qjMakFHDy7sC'
  },
  {
    key: '2',
    title: 'Evolutionary Perspectives on Schizophrenia',
    authors: ['Joseph Polimeni', 'Jeffrey P. Reiss'],
    year: '2003',
    journal: 'The Canadian Journal of Psychiatry, Volume: 48, Issue: 1',
    hrefPDF: './pdf/evolutionary-perspectives-on-schizophrenia.pdf',
    hrefGoogleScholar:
      'https://scholar.google.ca/citations?view_op=view_citation&hl=en&user=2K0Hs3kAAAAJ&citation_for_view=2K0Hs3kAAAAJ:UeHWp8X0CEIC'
  },
  {
    title: 'The crisis of psychiatry - insights and prospects from evolutionary theory',
    authors: [
      'Martin Brüne',
      'Jay Belsky',
      'Horacio Fabrega',
      'Hay R Feierman',
      'Paul Gilbert',
      'Kalman Glantz',
      'Joseph Polimeni',
      'John S Price',
      'Julio Sanjuan',
      'Roger Sullivan',
      'Alfonso Troisi',
      'Daniel R Wilson'
    ],
    year: '2012',
    journal: 'World Psychiatry, Volume: 11, Issue: 1',
    hrefPDF: './pdf/the-crisis-of-psychiatry.pdf',
    hrefGoogleScholar:
      'https://scholar.google.ca/citations?view_op=view_citation&hl=en&user=2K0Hs3kAAAAJ&citation_for_view=2K0Hs3kAAAAJ:WF5omc3nYNoC'
  },
  {
    title:
      'Could obsessive-compulsive disorder have originated as a group-selected adaptive trait in traditional societies?',
    authors: ['Joseph Polimeni', 'Jeffrey P. Reiss', 'Jitender Sareen'],
    year: '2005',
    journal: 'Medical Hypotheses, Volume: 65, Issue: 4',
    hrefPDF: './pdf/could-ocd-have-originated-as-a-group-selected-adaptive-trait-in-traditional-societies.pdf',
    hrefGoogleScholar:
      'https://scholar.google.ca/citations?view_op=view_citation&hl=en&user=2K0Hs3kAAAAJ&citation_for_view=2K0Hs3kAAAAJ:IjCSPb-OGe4C'
  },
  {
    title:
      'The Shamanistic Theory of Schizophrenia: The Evidence for Schizophrenia as a Vestigial Phenotypic Behavior Originating in Paleolithic Shamanism',
    authors: ['Joseph Polimeni'],
    year: '2022',
    journal: 'Journal of Anthropological and Archaeological Sciences, Volume: 6, Issue: 3',
    hrefPDF: './pdf/the-shamanistic-theory-of-schizophrenia.pdf',
    hrefGoogleScholar:
      'https://scholar.google.ca/citations?view_op=view_citation&hl=en&user=2K0Hs3kAAAAJ&citation_for_view=2K0Hs3kAAAAJ:2osOgNQ5qMEC'
  },
  {
    title:
      'Jokes optimise social norms, laughter synchronises social attitudes: an evolutionary hypothesis on the origins of humour',
    authors: ['Joseph Polimeni'],
    year: '2016',
    journal: 'European Journal of Humour Research',
    hrefPDF: './pdf/jokes-optimise-social-norms-laughter-synchronises-social-attitudes.pdf'
  }
];

const posterData = [
  {
    title: 'Invesitgating magico-religious delusions and hallucinations in schizophrenia and other psychotic disorders',
    authors: [
      'Joseph Polimeni',
      'Scott Hill',
      'Vishal Kaushik',
      'Amir Shamlou',
      'Melanie Richards',
      'Jacquelyne Wong',
      'Daniel Alvarez'
    ],
    year: '2022',
    hrefPDF:
      './pdf/invesitgating-magico-religious-delusions-and-hallucinations-in-schizophrenia-and-other-psychotic-disorders.pdf'
  },
  {
    title: 'Word Association in Schizophrenia: An fMRI Study',
    authors: [
      'Jeffrey P. Reiss',
      'Joseph Polimeni',
      'Darren W. Campbell',
      'Jitender Sareen',
      'Martin P. Paulus',
      'Lawrence N. Ryner',
      'R. Bruce Bolster'
    ],
    year: '2007',
    hrefPDF: './pdf/word-association-in-schizophrenia.pdf'
  }
];

const SelectPapers = () => {
  return (
    <Card
      title={
        <Title level={2} style={{ margin: '10px 0px 0px 0px ' }}>
          Select Papers
        </Title>
      }
    >
      <List
        bordered
        itemLayout="horizontal"
        dataSource={papersData}
        renderItem={(item, i) => (
          <List.Item key={i}>
            <PaperListItem
              title={item.title}
              year={item.year}
              journal={item.journal}
              authors={item.authors}
              hrefPDF={item.hrefPDF}
              hrefGoogleScholar={item.hrefGoogleScholar}
            />
          </List.Item>
        )}
      />
      <Divider />
      <Title level={3} style={{ margin: '10px 0px 10px 0px ' }}>
        Posters
      </Title>
      <List
        bordered
        itemLayout="horizontal"
        dataSource={posterData}
        renderItem={(item, i) => (
          <List.Item key={i}>
            <PaperListItem title={item.title} year={item.year} authors={item.authors} hrefPDF={item.hrefPDF} />
          </List.Item>
        )}
      />
    </Card>
  );
};
export default SelectPapers;
