import { Row, Col, Typography, Image } from 'antd';
import { useState, useEffect } from 'react';
import YoutubeEmbed from './YoutubeEmbed';
import YouTube, { YouTubePlayer } from 'react-youtube';

const { Paragraph, Title, Text } = Typography;

const defaultOpts = {
  height: '480',
  width: '853',
  playerVars: {
    autoplay: 1
  }
};

export interface YoutubeNewsItemProps {
  embedID: string;
  title: string;
  description: string;
  date: Date;
  useCustomThumbnail?: string;
}

const YoutubeNewsItem = (prop: YoutubeNewsItemProps) => {
  const [isPreviewVisible, setPreviewVisible] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [videoElement, setVideoElement]: YouTubePlayer = useState(null);

  const updatePreview = (isVisible: boolean) => {
    if (videoElement) {
      if (isVisible) videoElement.target.playVideo();
      else videoElement.target.pauseVideo();
    }
    setPreviewVisible(isVisible);
  };

  const _onPlayerReady = (event: YouTubePlayer) => {
    setVideoElement(event);
  };

  const updateMedia = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  if (width > 860) {
    return (
      <div style={{ width: '100%' }}>
        <Row gutter={32}>
          <Col md={12} lg={11} xl={12}>
            <Image
              src={prop.useCustomThumbnail ?? `https://img.youtube.com/vi/${prop.embedID}/maxresdefault.jpg`}
              preview={{
                visible: isPreviewVisible,
                imageRender: () => (
                  <YouTube
                    videoId={prop.embedID} // defaults -> ''
                    opts={defaultOpts} // defaults -> {}
                    onReady={_onPlayerReady}
                  />
                ),
                onVisibleChange: (visible, prevVisible) => updatePreview(visible),
                toolbarRender: () => null
              }}
            />
          </Col>

          <Col md={12} lg={13} xl={12}>
            <div onClick={() => updatePreview(true)} style={{ cursor: 'pointer' }}>
              <Title level={width > 1060 ? 4 : 5} style={{ margin: 0 }}>
                {prop.title}
              </Title>
              <Text type="secondary">{prop.date.toDateString()}</Text>
              <Paragraph>{prop.description}</Paragraph>
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <div style={{ width: '100%' }}>
      <Title level={5} style={{ marginTop: 0, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
        {prop.title}
      </Title>
      <YoutubeEmbed embedID={prop.embedID} />
    </div>
  );
};

export default YoutubeNewsItem;
