export const Pallete = {
  ShamanYellow: '#edd11d',
  White: '#F5F5F5',
  Silver: '#eaebeb',
  PrimaryLight: '#82a2b5',
  PrimaryBlue: '#1182d3',
  SecondaryBlue: '#48658c',
  PrimaryPurple: '#4d5778',
  SecondaryPurple: '#323449',
  Black: '#191924',
  VoxYellow: '#fef202'
};
