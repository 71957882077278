import { Carousel } from 'antd';
import '../App.css';

export interface CustomCarouselProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const CustomCarousel = (prop: CustomCarouselProps) => {
  return (
    <Carousel autoplay autoplaySpeed={8000} dots style={prop.style}>
      {prop.children}
    </Carousel>
  );
};

export default CustomCarousel;
