import { Flex, Typography } from 'antd';

const { Title, Text } = Typography;

export interface NewsListItemProps {
  title: string;
  date: Date;
  children: React.ReactNode;
}

const NewsListItem = (prop: NewsListItemProps) => {
  return (
    <div style={{ width: '100%' }}>
      <Flex justify="space-between">
        <div>
          <Title level={5} style={{ margin: '0px' }}>
            {prop.title}
          </Title>
        </div>
        <div style={{ textAlign: 'right', minWidth: 120 }}>
          <Text type="secondary">{prop.date.toDateString()}</Text>
        </div>
      </Flex>
      <div>{prop.children}</div>
    </div>
  );
};

export default NewsListItem;
