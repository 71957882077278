import { Card, Result, Button } from 'antd';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import '../App.css';

// Map path extentions from the old website to the new one
const redirectMap = new Map<string, string>([
  ['/index.html', '/'],
  ['/joseph-polimeni.html', '/'],
  ['/excerpts.html', '/shamans-among-us'],
  ['/latest.html', '/latest-news'],
  ['/learn-more.html', '/shamans-among-us'],
  ['/contact.html', '/'],
  ['/purchase.html', '/shamans-among-us']
]);

const Error = () => {
  const navigate = useNavigate();

  useEffect(() => {
    let path = window.location.pathname.toLowerCase();
    let realPath = redirectMap.get(path);
    if (realPath) {
      navigate(realPath);
    }
  });

  return (
    <div>
      <Card
        style={{
          width: '340px',
          height: 'auto',
          margin: '0 auto',
          position: 'relative'
        }}
      >
        <Result
          icon={<QuestionCircleTwoTone />}
          style={{ height: '100%' }}
          title={'Page not found!'}
          subTitle={`Sorry, this page does not exist.`}
          extra={
            <Button href="/" type="primary">
              Return Home
            </Button>
          }
        />
      </Card>
    </div>
  );
};
export default Error;
