import { useState, useEffect } from 'react';
import { Row, Col, Flex, Tag, Typography, Button, Tooltip, ConfigProvider } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { Pallete } from '../style';

const { Title, Text } = Typography;

export interface PaperListItemProps {
  title: string;
  authors: string[];
  year: string;
  journal?: string;
  hrefPDF: string;
  hrefGoogleScholar?: string;
}

const PaperListItem = (prop: PaperListItemProps) => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 554);
  const [openAuthorList, setOpenAuthorList] = useState(false);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 554);
  };

  const onDownloadButtonClick = () => {
    // fetch PDF file
    fetch(prop.hrefPDF).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);

        // Setting various property values
        let alink = document.createElement('a');
        alink.href = fileURL;
        let name = prop.hrefPDF.split('/')[2];
        alink.download = name;
        alink.click();
      });
    });
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  interface LabelProps {
    label: string;
    children: React.ReactNode;
  }

  const Label = (prop: LabelProps) => {
    return (
      <Flex style={{ marginTop: 4 }}>
        <Col flex="none" style={{ width: '54px', textAlign: 'right', marginRight: 10 }}>
          {prop.label}:
        </Col>
        <Col flex="auto">{prop.children}</Col>
      </Flex>
    );
  };

  const OptionButtons = () => {
    return (
      <ConfigProvider
        theme={{
          components: {
            Button: {
              defaultBg: Pallete.White,
              defaultBorderColor: Pallete.SecondaryBlue,
              defaultColor: Pallete.SecondaryBlue,
              defaultHoverBg: Pallete.PrimaryPurple,
              defaultHoverBorderColor: Pallete.White,
              defaultHoverColor: Pallete.White,
              defaultActiveBg: Pallete.SecondaryPurple,
              defaultActiveBorderColor: Pallete.Silver,
              defaultActiveColor: Pallete.Silver
            }
          }
        }}
      >
        <Flex gap={5}>
          <Tooltip title="View on Google Scholar">
            {prop.hrefGoogleScholar && (
              <Button
                target="_blank"
                style={{ height: 32 }}
                href={prop.hrefGoogleScholar}
                shape="circle"
                type="link"
                icon={<img alt="google scholar" src="./images/google_scholar_logo.svg" height={28} />}
              />
            )}
          </Tooltip>
          <Tooltip title="Download PDF">
            <Button onClick={() => onDownloadButtonClick()} shape="circle" icon={<DownloadOutlined />} />
          </Tooltip>
          <Button href={prop.hrefPDF} target="_blank" rel="noopener noreferrer" type="default" shape="round">
            Preview
          </Button>
        </Flex>
      </ConfigProvider>
    );
  };

  const AuthorList = () => {
    let viewList = [...prop.authors];

    if (!openAuthorList && viewList.length > 3) {
      viewList.splice(3, viewList.length - 3);
      return (
        <Flex gap={2} wrap="wrap" align="flex-start">
          {viewList.map((name, index) => (
            <Tag key={index}>
              {name} {viewList.length > 2 && <sup>{index + 1}</sup>}
            </Tag>
          ))}
          <Tag color="geekblue" onClick={() => setOpenAuthorList(true)} style={{ cursor: 'pointer' }}>
            <b>...</b>
            <sup>{}</sup>
          </Tag>
        </Flex>
      );
    }

    return (
      <Flex gap={2} wrap="wrap" align="flex-start">
        {prop.authors.map((name, index) => (
          <Tag key={index}>
            {name}
            {viewList.length > 2 && <sup>{index + 1}</sup>}
          </Tag>
        ))}
        {viewList.length > 3 && (
          <Tag color="geekblue" onClick={() => setOpenAuthorList(false)} style={{ cursor: 'pointer' }}>
            Hide
            <sup>{}</sup>
          </Tag>
        )}
      </Flex>
    );
  };

  return (
    <div style={{ width: '100%' }}>
      <Flex justify="space-between">
        <div>
          <Row>
            <Flex gap={4} wrap="wrap">
              <Title level={5} style={{ margin: '0px 0px 5px 0px' }}>
                {prop.title} <Text type="secondary">{`(${prop.year})`}</Text>
              </Title>
            </Flex>
          </Row>
          {isDesktop && (
            <div>
              <Label label="Authors">
                <AuthorList />
              </Label>
              {prop.journal && (
                <Label label="Journal">
                  <Text type="secondary">{prop.journal}</Text>
                </Label>
              )}
            </div>
          )}

          {!isDesktop && (
            <Row style={{ paddingTop: 10 }}>
              <OptionButtons />
            </Row>
          )}
        </div>
        {isDesktop && <OptionButtons />}
      </Flex>
    </div>
  );
};

export default PaperListItem;
