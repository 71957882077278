import { Card, Divider, Typography, ConfigProvider } from 'antd';
import '../App.css';

const { Title } = Typography;

const Research = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBorderSecondary: '#82a2b5'
        }
      }}
    >
      <Card
        className="bg-content"
        style={{ minHeight: 540 }}
        title={
          <Title level={2} style={{ margin: '10px 0px 0px 0px ' }}>
            Research Biography
          </Title>
        }
      >
        <Title style={{ marginTop: 0 }} level={4}>
          Evolutionary Research
        </Title>
        <p>
          Dr. Polimeni is an internationally recognized evolutionary psychiatrist. He has written several highly cited
          papers concerning schizophrenia, obsessive-compulsive disorder, and the application of evolutionary theory to
          psychiatric disorders. His book
          <a href="/shamans-among-us"> Shamans Among Us: schizophrenia, shamanism, and the origins of religion </a>
          explores the evolutionary origins of psychosis.
        </p>
        <Divider />
        <Title level={4}>Humor Research</Title>
        <p>
          Dr. Polimeni has published several scientific papers on the cognitive structure of humor. Dr. Polimeni (and
          coauthor Jeff Reiss) have been credited with having established a minimum estimate for the evolutionary age of
          humor in Homo sapiens (i.e. 40,000 years).
        </p>
        <Divider />
        <Title level={4}>fMRI Research</Title>
        <p>
          Dr. Polimeni was involved with fMRI research in the late 1990s when the technology was first introduced to
          psychiatry. From 2000 to 2009, he was a member of the Neuroimaging section, Department of Psychiatry,
          University of Manitoba. From 1999 to 2005, he was a Visiting Researcher at the Institute of Biodiagnostics,
          National Research Council of Canada.
        </p>
      </Card>
    </ConfigProvider>
  );
};
export default Research;
