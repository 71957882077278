import { Card, Typography, Divider, ConfigProvider } from 'antd';
import '../App.css';

const { Title } = Typography;

const Clinical = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBorderSecondary: '#82a2b5'
        }
      }}
    >
      <Card
        className="bg-content"
        style={{ minHeight: 540 }}
        title={
          <Title level={2} style={{ margin: '10px 0px 0px 0px ' }}>
            Clinical Biography
          </Title>
        }
      >
        <Title style={{ marginTop: 0 }} level={4}>
          General Psychiatry
        </Title>
        <p>
          Dr. Polimeni is a general psychiatrist and associate professor at the University of Manitoba, Department of
          Psychiatry. He has over 30 years of clinical experience and has worked in the following clinical areas:
          inpatient, outpatient, emergency, consult-liaison and forensics psychiatry. Dr. Polimeni has been a
          co-investigator in over 30 pharmaceutical trials.
        </p>
        <Divider />
        <Title level={4}>Occupational Medicine</Title>
        <p>
          Dr. Polimeni has been a psychiatric consultant, in the role of occupational physician, to a number of
          corporations, including CN Railways, Air Canada, Manitoba Hydro, Boeing and The City of Winnipeg. From 1998 to
          2017, he was a regular consultant to the Department of National Defence, primarily conducting pilot
          assessments. In 2004, he helped establish the Operational Stress Injury Clinic at Deer Lodge Hospital,
          treating PTSD in veterans, RCMP officers, and active military personnel.
        </p>
      </Card>
    </ConfigProvider>
  );
};
export default Clinical;
