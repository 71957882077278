import { Collapse, CollapseProps, ConfigProvider } from 'antd';

const items: CollapseProps['items'] = [
  {
    key: '1',
    label: 'About the shamanistic theory (from Chapter 1: Evolution Changes Everything)',
    children: (
      <p>
        In its simplest form, the shamanistic theory of schizophrenia says that people with schizophrenia are the modern
        manifestation of prehistoric tribal shamans. In other words, the inborn cognitive factors or personality style
        that would have predisposed certain people to become shamans is the same psychological mindset that underlies
        schizophrenia. Although the idea is straightforward, a proper evaluation of the hypothesis is complicated and
        must take into account the latest discoveries from psychiatry, medical history, evolutionary science,
        anthropology, psychology, religious studies and genetics. The primary purpose of this book is to put forward a
        contemporary version of the shamanistic theory of schizophrenia.
      </p>
    )
  },
  {
    key: '2',
    label: 'Faulty reasoning associated with psychosis (from Chapter 5: The Silver Lining of Psychosis)',
    children: (
      <p>
        Faulty reasoning associated with psychosis has been investigated by a number of research groups (see the
        excellent 1999 review by Garety and Freeman). A frequent finding is that schizophrenia patients, particularly
        those with delusions, have a tendency to jump to conclusions (Moritz and Woodward 2005; Menon et al. 2006).
        Schizophrenia may also be associated with a "generalized bias against disconfirmatory evidence" (Moritz and
        Woodward 2006; Woodward et al. 2008). A few studies have suggested that, specifically during probability tasks,
        people with schizophrenia tend to jump to conclusions more often than controls do (Garety and Freeman 1999).
        However, a reinterpretation of some of this data suggested that schizophrenia patients actually made better
        Bayesian inferences than control subjects because the latter were overly cautious (Maher 1992)!
      </p>
    )
  },
  {
    key: '3',
    label: 'The roles of shamans (from Chapter 8: Shamanism)',
    children: (
      <p>
        Shamans are believed to possess spiritual powers involving communication with a world beyond the observable
        realm. Because shamans act as conduits to a greater spirit world, they are seen as the religious leaders of
        their communities. One of the most notable roles for shamans is endeavoring to heal the sick (McClenon 2002;
        Vitebsky 1995). They often lead elaborate rituals meant to rid the body of malevolent spirits, the usual
        supposed reason for illness in tribal societies (Narby and Huxley 2001). A shaman normally presides over rites
        of passage such as birth, coming of age, marriage and death. Rituals involving a community's procurement of
        food, such as rain dances or divining the movement of prey, are also under a shaman's purview, as are ceremonies
        in anticipation of war.
      </p>
    )
  },
  {
    key: '4',
    label: 'Comparison to other psychiatric illnesses (from Chapter 11: Finishing Touches)',
    children: (
      <p>
        Other common psychiatric illnesses have some sort of rational explanation within reach. Depression appears to
        have something to do with mammalian expressions of submission, while anxiety is connected to fear and fleeing.
        Obsessive-compulsiveness may be linked to overly persistent habits, and mental retardation is the result of
        incomplete brain development. In contrast, schizophrenia is not an obvious extension of any other behavior and
        has no tangible analogue in nature. The motor and sensory systems have gone awry but not in a haphazard or
        unsystematic way. Clear patterns exist but are inexplicable in the context of twenty-first century life. Despite
        two centuries of scrutiny, mainstream psychiatry has yet to tell us anything particularly insightful about the
        condition. Schizophrenia seems too elusive for twenty-first century science, perhaps because the psychiatric
        establishment keeps attacking the problem from the same angle, trying to put a square peg in a round hole.
      </p>
    )
  },
  {
    key: '5',
    label: 'The resemblance between schizophrenia and shamanism (from Chapter 11: Finishing Touches)',
    children: (
      <p>
        The resemblance of schizophrenia to the ancient institution of shamanism surpasses coincidence. The personal
        histories of shamans so often contain episodes of serious mental disturbance, including numerous accounts of
        spiritual hallucinations. Shamans become engrossed with magico-religious beliefs and suspicions of otherworldly
        threats. They have the reputation of being solitary, unsociable and odd. Just like schizophrenia, which exists
        in every part of the globe, shamanism has been practiced on every continent. Every known traditional society is
        known to have carved out a dedicated role for such magico-religious practitioners, and up until 10,000 years
        ago, all humans lived in traditional societies. It is therefore likely that for tens of thousands of years,
        shamans were present wherever humans roamed the earth. Where schizophrenia is common, magico-religious healers
        are not &mdash; and where shamans practice, insanity becomes spiritual.
      </p>
    )
  }
];

const BookExcerpts = () => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Collapse: {
            headerBg: '#E4E9F1'
          }
        }
      }}
    >
      <Collapse accordion items={items} defaultActiveKey={['1']} />
    </ConfigProvider>
  );
};

export default BookExcerpts;
