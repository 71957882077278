import { Row, Col, Typography, Flex } from 'antd';
import { useState, useEffect } from 'react';
const { Paragraph, Title, Text } = Typography;

export interface VoxArticleItemProps {
  date: Date;
}

const VoxArticleItem = (prop: VoxArticleItemProps) => {
  const [width, setWidth] = useState(window.innerWidth);

  const updateMedia = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  const mediaDiv = () => {
    return (
      <a rel="noreferrer" target="_blank" href="https://www.vox.com/the-highlight/23486818/science-humor-comedy">
        <div className="vox-div">
          <video
            autoPlay
            muted
            loop
            width={'100%'}
            src="https://cdn.vox-cdn.com/thumbor/1dN60_b1Fexsxw8rNGthN1pqkvw=/0x0:1430x804/2070x1164/filters:focal(601x288:829x516):gifv():no_upscale()/cdn.vox-cdn.com/uploads/chorus_image/image/71739955/LEDE_IMAGE_GIF.0.gif"
          />
          <div className="vox-overlay">
            <Flex
              wrap="nowrap"
              align="center"
              justify="center"
              style={{
                width: '100%',
                height: '100%'
              }}
            >
              <img
                style={{
                  width: '60%',
                  height: 'auto'
                }}
                alt=""
                src="./images/vox_logo.svg"
              />
            </Flex>
          </div>
        </div>
      </a>
    );
  };

  if (width > 860) {
    return (
      <div style={{ width: '100%' }}>
        <Row gutter={32}>
          <Col md={12} lg={11} xl={12}>
            {mediaDiv()}
          </Col>
          <Col md={12} lg={13} xl={12}>
            <a rel="noreferrer" target="_blank" href="https://www.vox.com/the-highlight/23486818/science-humor-comedy">
              <Title level={width > 1060 ? 4 : 5} style={{ margin: 0 }}>
                Vox: The very serious science of humor
              </Title>
              <Text type="secondary">{prop.date.toDateString()}</Text>
              <Paragraph>THE HIGHLIGHT: How studying what tickles our funny bone can help explain who we are</Paragraph>
            </a>
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <div style={{ width: '100%' }}>
      <Title level={5} style={{ marginTop: 0, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
        Vox: The very serious science of humor
      </Title>
      {mediaDiv()}
    </div>
  );
};

export default VoxArticleItem;
