import { Button, ConfigProvider } from 'antd';
import { Pallete } from '../style';

export interface BookHeaderButtonProps {
  text: string;
}

const BookHeaderButton = (prop: BookHeaderButtonProps) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            defaultBg: Pallete.ShamanYellow,
            defaultBorderColor: Pallete.Black,
            defaultColor: Pallete.Black,
            defaultActiveBg: '#D0B611',
            defaultActiveBorderColor: Pallete.Black,
            defaultActiveColor: Pallete.Black,
            defaultHoverBg: '#E2C712',
            defaultHoverBorderColor: Pallete.Black,
            defaultHoverColor: Pallete.Black,
            borderRadiusLG: 8
          }
        }
      }}
    >
      <Button size="large">{prop.text}</Button>
    </ConfigProvider>
  );
};

export default BookHeaderButton;
