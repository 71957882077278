import { useState, useEffect } from 'react';
import { Button, Card, Flex, Row, Col, Image, ConfigProvider, Divider } from 'antd';
import {
  BookOutlined,
  FilePdfOutlined,
  ReadOutlined,
  FileTextOutlined,
  AppleOutlined,
  AmazonOutlined
} from '@ant-design/icons';
import { BookButton, BookExcerpts, CustomCarousel } from '../components';
import { Pallete } from '../style';
import '../App.css';

const Book = () => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 768);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 768);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  return (
    <Card>
      <Row gutter={[32, 32]} wrap>
        <Col lg={7} md={8} sm={24}>
          <Image.PreviewGroup items={['./images/book/cover1360.jpg', './images/book/back1360.jpg']}>
            <Image style={{ padding: 8 }} src="./images/book/cover1360.jpg" />
          </Image.PreviewGroup>
          <ConfigProvider
            theme={{
              components: {
                Button: {
                  defaultActiveBorderColor: Pallete.Silver,
                  defaultActiveColor: Pallete.Black,
                  defaultActiveBg: Pallete.Silver,
                  defaultHoverBg: Pallete.White,
                  defaultHoverColor: Pallete.Black,
                  defaultHoverBorderColor: Pallete.Silver
                }
              }
            }}
          >
            <Button block target="_blank" href="https://a.co/bAGqBuv">
              Sample Preview
            </Button>
          </ConfigProvider>
        </Col>
        <Col lg={17} md={16} sm={24}>
          <h1>SHAMANS AMONG US: Schizophrenia, Shamanism and the Evolutionary Origins of Religion</h1>
          <h3>By: Joseph Polimeni</h3>
          <p>
            Schizophrenia is one of the most enigmatic human experiences. While it can cause terrible distress, it
            doesn't fit the mold of a classic medical disease. In Shamans Among Us, Joseph Polimeni shows that today's
            schizophrenia patients are no less than the modern manifestation of tribal shamans, people vital to the
            success of early human cultures. Shamans Among Us is the most detailed and comprehensive evolutionary theory
            yet assembled to explain a specific psychiatric diagnosis.
          </p>
          <Divider />
          <h3>Purchase Links</h3>
          <Flex gap={isDesktop ? 'middle' : 'small'} wrap="wrap" justify={isDesktop ? 'left' : 'space-between'}>
            <BookButton
              text="Hard Cover"
              mobileMode={!isDesktop}
              icon={<BookOutlined />}
              href="http://www.lulu.com/shop/joseph-polimeni/shamans-among-us-schizophrenia-shamanism-and-the-evolutionary-origins-of-religion/hardcover/product-20651028.html"
            />
            <BookButton
              text="Paperback"
              mobileMode={!isDesktop}
              icon={<ReadOutlined />}
              href="http://www.lulu.com/shop/joseph-polimeni/shamans-among-us-schizophrenia-shamanism-and-the-evolutionary-origins-of-religion/paperback/product-20536515.html"
            />
            <BookButton
              text="Apple Books"
              mobileMode={!isDesktop}
              icon={<AppleOutlined />}
              href="https://itunes.apple.com/ca/book/shamans-among-us/id598426233?mt=11"
            />
            <BookButton text="Kindle" mobileMode={!isDesktop} icon={<AmazonOutlined />} href="https://a.co/d/2Pn9XfN" />
            <BookButton
              text="PDF"
              mobileMode={!isDesktop}
              icon={<FilePdfOutlined />}
              href="http://www.lulu.com/shop/joseph-polimeni/shamans-among-us-schizophrenia-shamanism-and-the-evolutionary-origins-of-religion/ebook/product-20572163.html"
            />
            <BookButton
              text="EPUB"
              mobileMode={!isDesktop}
              icon={<FileTextOutlined />}
              href="http://www.lulu.com/shop/joseph-polimeni/shamans-among-us-schizophrenia-shamanism-and-the-evolutionary-origins-of-religion/ebook/product-20651086.html"
            />
          </Flex>
        </Col>
      </Row>
      <Divider />
      <Row gutter={[32, 32]} justify="center">
        <Col lg={11} md={11} sm={24} style={{ backgroundColor: '#E9E2F3' }}>
          <h3>About the Author</h3>
          <p style={{ marginTop: 0, textAlign: 'justify' }}>
            Joseph Polimeni is an associate professor of psychiatry at the University of Manitoba. He has practiced
            general psychiatry for over twenty years. Dr. Polimeni is an internationally recognized evolutionary
            psychiatrist who has published theoretical papers on the evolutionary origins of schizophrenia,
            obsessive-compulsive disorder and humor. His research has been presented at international meetings and
            covered by media outlets such as the CBC and Discovery Channel News. He lives in Winnipeg with his wife and
            two sons.
          </p>
        </Col>
        {isDesktop ? (
          <Col>
            <Divider type="vertical" style={{ height: '100%' }} />
          </Col>
        ) : (
          <></>
        )}

        <Col lg={11} md={11} sm={24} style={{ backgroundColor: '#FFFAEB' }}>
          <h3>Endorsements</h3>
          <CustomCarousel>
            <div>
              <p style={{ marginTop: 0 }}>
                “Shamans Among Us is a thought-provoking essay on the idea that the psychology of people who we today
                diagnose with schizophrenia is akin to that of shamans in ancestral societies. Joseph Polimeni’s
                scholarly book challenges several traditional concepts of both evolutionary biology and medicine. I
                strongly recommend it to all those who dare to think outside the box.”
              </p>
              <p className="endorsement-author-text">
                – Martin Brüne, MD
                <br /> Author of Textbook of Evolutionary Psychiatry:
                <br /> The Origins of Psychopathology
              </p>
            </div>
            <div>
              <p style={{ marginTop: 0 }}>
                “What an aptly named book. Joseph Polimeni raises the interesting notion that present-day schizophrenics
                are, in a real sense, descendents of latter-day shamans— evolutionary selectees who served the important
                societal function of communication with the supernatural.”
              </p>
              <p className="endorsement-author-text">
                – David Koulack, PhD <br />
                Author of To Catch a Dream: Explorations of Dreaming
              </p>
            </div>
            <div>
              <p style={{ marginTop: 0 }}>
                “As with other evolutionary theories, it casts a new light on matters which were previously confusing
                and even inexplicable.”
              </p>
              <p className="endorsement-author-text">
                – John Price, DM, MRCP, MRCPsych, DPM
                <br />
                Co-author of Evolutionary Psychiatry:
                <br /> A New Beginning and Prophets, Cults and Madness
              </p>
            </div>
          </CustomCarousel>
        </Col>
      </Row>
      <Divider />

      <h3>Excerpts</h3>
      <BookExcerpts />
    </Card>
  );
};
export default Book;
