import '../App.css';

export interface YoutubeEmbedProps {
  embedID: string;
}

const YoutubeEmbed = (prop: YoutubeEmbedProps) => {
  return (
    <div className="video-responsive">
      <iframe
        width="853"
        height="480"
        src={`https://www.youtube.com/embed/${prop.embedID}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );
};
export default YoutubeEmbed;
