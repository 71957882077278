import { Layout, Menu, MenuProps, ConfigProvider } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import { Pallete } from '../style';
import BookHeaderButton from './BookHeaderButton';

const { Header } = Layout;

const bookButton = (popUpOpen: boolean) => {
  if (popUpOpen) return <BookHeaderButton text="Purchase Shamans Among Us" />;
  else
    return (
      <div className="header-menu-item">
        <BookHeaderButton text="Purchase Shamans Among Us" />
      </div>
    );
};

const navigation = (popUpOpen: boolean): MenuProps['items'] => {
  return [
    { label: <div className="header-menu-item">Home</div>, key: '/' },
    { label: <div className="header-menu-item">Clinical</div>, key: '/clinical-bio' },
    { label: <div className="header-menu-item">Research</div>, key: '/research-bio' },
    { label: <div className="header-menu-item">Select Papers</div>, key: '/select-papers' },
    { label: <div className="header-menu-item">Latest News</div>, key: '/latest-news' },
    {
      label: bookButton(popUpOpen),
      key: '/shamans-among-us'
    }
  ];
};

export interface HeaderProps {}

const SiteHeader = (prop: HeaderProps) => {
  const [pathName, setPathName] = useState(window.location.pathname);
  const [isBookSelected, setBookSelected] = useState(window.location.pathname === '/shamans-among-us');
  const [isPopUp, setPopUp] = useState(false);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 740);
  const navigate = useNavigate();

  const handleMenuClick = (key: string) => {
    if (key) {
      setBookSelected(key === '/shamans-among-us');
      setPathName(key);
      if (key !== pathName) navigate(key);
    }
  };

  const onSubMenuChange = (keys: string[]) => {
    if (keys.length !== 0) setPopUp(true);
  };

  const updateMedia = () => {
    setDesktop(window.innerWidth > 740);
    setPopUp(false);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  return (
    <Header
      className="header"
      style={{
        display: 'flex',
        backgroundColor: Pallete.SecondaryPurple,
        overflowX: 'hidden',
        height: 64,
        justifyContent: 'space-between'
      }}
    >
      <div className="title-div">
        <span className="title-text">Joseph Polimeni</span>
        <span className="title-text-com">.com</span>
      </div>
      <ConfigProvider
        theme={{
          components: {
            Menu: {
              darkItemSelectedBg: isBookSelected ? Pallete.SecondaryPurple : Pallete.PrimaryPurple,
              darkItemBg: Pallete.SecondaryPurple,
              darkPopupBg: Pallete.SecondaryPurple,
              darkSubMenuItemBg: isBookSelected ? Pallete.SecondaryPurple : Pallete.PrimaryBlue
            }
          }
        }}
      >
        <Menu
          theme="dark"
          mode="horizontal"
          selectedKeys={[pathName]}
          items={navigation(isPopUp)}
          onClick={(a) => handleMenuClick(a.key)}
          style={{
            flex: 'auto',
            justifyContent: 'right',
            maxWidth: isDesktop ? window.innerWidth : 100
          }}
          onOpenChange={(keys) => onSubMenuChange(keys)}
          overflowedIndicator={<MenuOutlined />}
        />
      </ConfigProvider>
    </Header>
  );
};

export default SiteHeader;
