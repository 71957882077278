import { Card, Row, Col, Divider, Typography, ConfigProvider } from 'antd';
import '../App.css';

const { Link } = Typography;

const Home = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBorderSecondary: '#82a2b5'
        }
      }}
    >
      <Row gutter={[32, 32]} wrap>
        <Col lg={8} md={10} sm={24}>
          <Card
            className="bg-content"
            style={{ height: '100%' }}
            cover={<img alt="portrait" width="auto" height="auto" src={'./images/portrait.jpg'} />}
          >
            <p>
              <b>Joseph Polimeni MD FRCPC</b>
              <br />
              325-530 Kenaston Blvd
              <br />
              Winnipeg MB R3N 1Z4
              <br />
              Canada
            </p>
            <Link href="mailto: jpolimeni@shaw.ca" copyable={true} style={{ fontSize: 16 }}>
              jpolimeni@shaw.ca
            </Link>
          </Card>
        </Col>
        <Col lg={16} md={14} sm={24}>
          <Card title="About" className="bg-content" style={{ height: '100%' }}>
            <p style={{ margin: 0 }}>
              Dr. Polimeni is a general psychiatrist and associate professor at the University of Manitoba. His clinical
              focus is occupational medicine; providing Independent Medical Examinations (IMEs) to employers, insurers
              and licensing bodies. Dr. Polimeni has conducted over 1500 IMEs. He offers both in-person and telehealth
              assessments. <br /> <br /> If you are a case manager or third-party representative, please contact Dr.
              Polimeni’s clinical admin assistant, Ms. Kara Slobodian, at{' '}
              <a href="mailto: dgill@mymts.ca">dgill@mymts.ca</a> (Fax: 1-833-790-2244).
            </p>
            <Divider />
            <p>
              Dr. Polimeni’s primary area of research has been the application of evolutionary theory to psychiatric
              conditions. He has peer-reviewed publications; including invited commentaries about diagnostic
              controversies, and his book <a href="/shamans-among-us">Shamans Among Us</a> , about the evolutionary
              origins of psychosis. Dr. Polimeni is also an internationally recognized expert in the science of humor.
            </p>
          </Card>
        </Col>
      </Row>
    </ConfigProvider>
  );
};
export default Home;
